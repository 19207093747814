export default {
	/* Page Load */
	'nissan-t3-tagging-TrackPageLoad': {
		event: 'general.bottom.of.page'
	},
	/* Link Click */
	'nissan-t3-tagging-TrackToggle': {
		uniqueEvent: 'showroom-toggle'
	},
	'nissan-t3-tagging-TrackAccordion': {
		uniqueEvent: 'detailed-specs-accordion'
	},
	'nissan-t3-tagging-TrackLinkClick': {
		event: [
			'text.link',
			'button.link',
			'generic.link',
			'link.hproduct.click',
			'image.link',
			'nav.link'
		]
	},
	/* Drop Down */
	'nissan-t3-tagging-TrackDropDown': {
		uniqueEvent: 'vlp-srp-sort-by-dropdown'
	},
	/* Lead Form Submission Success and Sign In / Sign Up */
	'nissan-t3-tagging-TrackSignIn': {
		formName: 'Mycars Login',
		event: 'form.confirmation'
	},
	'nissan-t3-tagging-TrackSignUp': {
		formName: 'Mycars Register',
		event: 'form.confirmation'
	},
	/* Schedule a Service Appt */
	'nissan-t3-tagging-TrackServiceAppointment': {
		formName: 'Schedule Service',
		event: 'form.confirmation'
	},
	'nissan-t3-tagging-TrackLeadFormSuccess': {
		event: 'form.confirmation'
	},
	/* Inventory Results */
	'nissan-t3-tagging-TrackInventoryResults': {
		event: 'filtered.search.results'
	},
	/* Click To Call */
	'nissan-t3-tagging-TrackClickToCall': {
		event: 'telephone.dial'
	},
	/* Carousel */
	'nissan-t3-tagging-TrackCarousel': {
		event: ['arrow.next', 'arrow.prev']
	},
	'nissan-t3-tagging-TrackOfferDetails': {
		event: 'offer.click'
	},
	/* Video */
	'nissan-t3-tagging-TrackVideoPlay': {
		event: 'vdp.video.play'
	},
	'nissan-t3-tagging-TrackVideoCompleted': {
		event: 'vdp.video.complete'
	}
};
