export default {
	BODYSHOP: {
		formName: {
			default: 'Service'
		}
	},
	BUILD_AND_PRICE: {
		formName: {
			default: 'Get a Quote'
		}
	},
	CONTACT: {
		formName: {
			default: 'General Contact'
		}
	},
	DEFAULT: {
		formName: {
			default: 'Other'
		}
	},
	EMAIL_A_FRIEND: {
		formName: {
			default: 'Email a friend'
		}
	},
	EMPLOYMENT: {
		formName: {
			default: 'Employment'
		}
	},
	EPRICE: {
		formName: {
			default: 'Get a Quote'
		}
	},
	FINANCE: {
		formName: {
			default: 'Finance'
		}
	},
	GLOBAL_VEHICLE_INCENTIVE_LEAD: {
		formName: {
			default: 'Specials'
		}
	},
	INFO_REQUEST: {
		formName: {
			default: 'Request More Info'
		}
	},
	INVENTORY_SEARCH_LEAD: {
		formName: {
			default: 'Vehicle Finder'
		}
	},
	INVENTORYFINDER: {
		formName: {
			default: 'Vehicle Finder'
		}
	},
	MAKE_AN_OFFER: {
		formName: {
			default: 'Make an Offer'
		}
	},
	MSLP: {
		formName: {
			default: 'Request More Info'
		}
	},
	MYCARS_FORGOT_PASSWORD: {
		formName: {
			default: 'Mycars Forgot Password'
		}
	},
	MYCARS_LOGIN: {
		formName: {
			default: 'Mycars Login'
		}
	},
	MYCARS_REGISTER: {
		formName: {
			default: 'Mycars Register'
		}
	},
	PARTS_ORDER: {
		formName: {
			default: 'Parts'
		}
	},
	PROMO_CTA_LEAD: {
		formName: {
			default: 'Promotions Request More Info'
		}
	},
	SCHEDULE_A_TEST_DRIVE: {
		formName: {
			default: 'Test Drive'
		}
	},
	SERVICE_APPT: {
		formName: {
			default: 'Schedule service'
		}
	},
	SHOWROOM: {
		formName: {
			default: 'Request More Info'
		}
	},
	SPECIALS_EMAIL_A_FRIEND: {
		formName: {
			default: 'Email a Friend'
		}
	},
	SPECIALS_INFO_REQUEST: {
		formName: {
			default: 'Specials'
		}
	},
	TEST_DRIVE: {
		formName: {
			default: 'Test Drive'
		}
	},
	TRADE_IN: {
		formName: {
			default: 'Trade in'
		}
	},
	VEHICLE_LEAD: {
		formName: {
			default: 'Request More Info'
		}
	}
};
