export default {
	CONTACT: {
		formType: 'Contact dealer',
		formCategory: 'Handraiser',
		formName: {
			'.ddc-mega-menu-nav': 'Navigation General Contact',
			default: 'General Contact'
		}
	},
	EMAIL_A_FRIEND: {
		formType: 'Other',
		formCategory: 'Handraiser',
		formName: {
			default: 'Email a friend'
		}
	},
	EPRICE: {
		formType: 'Request a Quote',
		formName: {
			default: 'Get a Quote'
		}
	},
	INSTANT_EPRICE: {
		formType: 'Request a Quote',
		formName: {
			default: 'Get a Quote'
		}
	},
	FINANCE: {
		formType: 'Other',
		formName: {
			default: 'Finance'
		}
	},
	INFO_REQUEST: {
		formType: 'Request a Quote',
		formName: {
			default: 'Request More Info'
		}
	},
	PARTS_ORDER: {
		formType: 'Other',
		formName: {
			default: 'Parts'
		}
	},
	SERVICE_APPT: {
		formType: 'Other',
		formName: {
			default: 'Schedule Service Appointment'
		}
	},
	TEST_DRIVE: {
		formType: 'Book a Test Drive',
		formName: {
			default: 'Test Drive'
		}
	}
};
