import { SET_MAPPING } from '../actions/types';

export default function foo(state = {}, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_MAPPING:
				return action.payload;

			// no default
		}
	}

	return state;
}
