import AdobeLaunchFormMappings from './AdobeLaunch/Form/index.js';
import AdobeLaunchPageAliasMappings from './AdobeLaunch/PageAlias/index';
import GTMEvents from './AdobeLaunch/GTM/index';

const {
	PageAliasMapping,
	GMPageAliasMapping,
	NissanPageAliasMapping
} = AdobeLaunchPageAliasMappings;

const { NissanGTMMapping, GTMMapping } = GTMEvents;

const {
	FormTrackingIdMapping,
	NissanFormTrackingIdMapping,
	GMFormTrackingIdMapping
} = AdobeLaunchFormMappings;

export const mappings = {
	adobe_launch: {
		nissan: {
			pageAlias: { ...PageAliasMapping, ...NissanPageAliasMapping },
			form: { ...FormTrackingIdMapping, ...NissanFormTrackingIdMapping },
			gtm: { ...NissanGTMMapping, ...GTMMapping }
		},
		gm: {
			pageAlias: { ...PageAliasMapping, ...GMPageAliasMapping },
			form: { ...FormTrackingIdMapping, ...GMFormTrackingIdMapping },
			gtm: { GTMMapping }
		},
		default: {
			pageAlias: { ...PageAliasMapping },
			form: { ...FormTrackingIdMapping },
			gtm: { ...GTMMapping }
		}
	},
	conversant: {
		default: {
			pageAlias: { ...PageAliasMapping },
			form: { ...FormTrackingIdMapping },
			gtm: { ...GTMMapping }
		}
	}
};
