import { mappings } from '../mappings/index';

export const formatMappingPrefs = prefs => {
	const prefCopy = { ...prefs };
	Object.keys(prefs).forEach(key => {
		if (prefs[key]) prefCopy[key] = prefs[key].toLowerCase();
	});
	return prefCopy;
};

/*
getNeededMappings():
	<preference name='adobe_launch' value='gm'/> 

	framework -> adobe_launch
	oem -> gm

	This function will find the necessary mappings based in preferences 

	getNeededMappings() finds PageAlias.adobe_launch.gm value which will be an object,
	and it will return this value in a new object shape that doesn't has the oem or gm as last nested level.

	Ex. PageAlias.adobe_launch.gm = {value} -> pageAlias.adobe_launch = {value}
*/

const getNeededMappings = (mapping, [framework, oem]) => {
	const newMapping = { ...mapping };
	const matchedMapping = mappings[framework] && mappings[framework][oem];
	if (matchedMapping) {
		newMapping[framework] = matchedMapping;
	}
	return newMapping;
};

export const getMappings = prefs => {
	return Object.entries(prefs).reduce(getNeededMappings, {});
};

const getPageAliasesMapping = mapping => mapping && mapping.pageAlias;

const matchPageAlias = (pageAlias, pageAliasObject = {}) => {
	const pageAliases = Object.keys(pageAliasObject);
	// Find which pageAlias matches with the currentPageAlias
	const matchedPageAlias = pageAliases.find(pageName =>
		pageAlias.match(pageName)
	);
	return matchedPageAlias;
};

/* eslint-disable no-param-reassign */
const replacePageAliasValue = (mapping, properties) => {
	if (properties) {
		// Replace all page alias object with just the properties for the current matched page
		mapping.pageAlias = properties;
	} else {
		mapping.pageAlias = {}; // it sends an empty object if there is no properties to replace
	}
};

const replacePageAliasMapping = (frameworkMapping, currentPageAlias) => {
	const pageAliasesObject = getPageAliasesMapping(frameworkMapping); // The page alias objects is the mapping with all the pageAliases properties.

	if (pageAliasesObject) {
		const pageAliasName = matchPageAlias(
			currentPageAlias,
			pageAliasesObject
		);
		const pageAliasProperties = pageAliasesObject[pageAliasName]; // Get the properties from the matched page alias ex. {pageType: ''}
		replacePageAliasValue(frameworkMapping, pageAliasProperties);
	}
	return frameworkMapping;
};

export const getPageAliasMapping = (frameworkMappings, currentPageAlias) => {
	const frameworkMappingsCopy = { ...frameworkMappings }; // iterate over the copy to not mutate original mapping values
	Object.keys(frameworkMappingsCopy).forEach(framework => {
		// framework will be adobe_launch, conversant, etc.
		const frameworkMapping = { ...frameworkMappingsCopy[framework] };
		frameworkMappingsCopy[framework] = replacePageAliasMapping(
			frameworkMapping,
			currentPageAlias
		);
	});
	return frameworkMappingsCopy; // mapping with the replaced page alias value
};

const publishEvent = event => {
	if (window && window.DDC && window.DDC.pubsub) {
		window.DDC.pubsub.publish(event);
	}
};

export const addMappingToWidgetData = mapping => {
	window.DDC = window.DDC || {};
	window.DDC.WidgetData = window.DDC.WidgetData || {};
	window.DDC.WidgetData['ws-tagging'] =
		window.DDC.WidgetData['ws-tagging'] || {};
	window.DDC.WidgetData['ws-tagging'].mapping = mapping;
	publishEvent('ws-tagging-ready');
};
