import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { addMappingToWidgetData } from '../utils/mappings';

import Component from '../components/Component';

const WidgetContainer = ({ mapping = {} }) => {
	useEffect(() => {
		addMappingToWidgetData(mapping);
	});
	return <Component />;
};

WidgetContainer.propTypes = {
	mapping: PropTypes.objectOf(PropTypes.object)
};

const mapStateToProps = state => {
	return {
		mapping: state.mapping
	};
};

const mapDispatchToProps = _dispatch => ({});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
