export default {
	'.*(BLOG_VIDEO|NEWS_DEFAULT).*': {
		pageType: 'blog',
		pageSubType: 'Blog'
	},
	'.*(ABOUT_DEFAULT|STAFF_LISTING|WHY_BUY).*': {
		pageType: 'company',
		pageSubType: 'About Us'
	},
	'.*(DIRECTIONS|HOURS).*': {
		pageType: 'hoursdirections',
		pageSubType: 'Hours and Directions'
	},
	'.*MYCARS_.*FAVORITES.*': {
		pageType: 'favorites',
		pageSubType: 'Saved Vehicles'
	},
	'.*BROCHURE.*': {
		pageType: 'requestinfo'
	},
	'.*(CONTACT_|CONTACT_DEFAULT).*': {
		pageType: 'company',
		pageSubType: 'Contact'
	},
	'.*FINANC.*': {
		pageType: 'finance'
	},
	'.*(LEAD_TRADE_IN|KBB.*CASH_OFFER).*': {
		pageType: 'tradein'
	},
	'.*TEST_DRIVE.*': {
		pageTool: 'book a test drive',
		pageType: 'testdrive',
		pageSubType: 'Test Drive'
	},
	'.*404.*': {
		pageType: 'pagenotfound',
		pageSubType: 'Page Not Found'
	},
	'.*PRIVACY.*': {
		pageType: 'helpcenter'
	},
	'^INDEX$': {
		pageType: 'homepage',
		pageSubType: 'Homepage'
	},
	'.*INCENTIVES.*': {
		pageType: 'offers',
		pageSubType: 'OEM Incentives'
	},
	'.*CALCULATOR_PAYMENT.*': {
		pageTool: 'payment calculator'
	},
	'.*PROMOTIONS_LISTING_NEW.*': {
		pageType: 'offers',
		pageSubType: 'New Vehicles Incentives'
	},
	'.*(PROMOTIONS|SPECIALS)_LISTING_PARTS.*': {
		pageType: 'parts',
		pageSubType: 'Parts Specials'
	},
	'.*(PROMOTIONS|SPECIALS)_LISTING_SERVICE.*': {
		pageType: 'serviceoffers',
		pageSubType: 'Service Specials'
	},
	'.*PROMOTIONS_LISTING_USED.*': {
		pageType: 'offers',
		pageSubType: 'Used Vehicles Incentives'
	},
	'.*(VEHICLES_ACCESSORIES|LISTING_ACCESSORY).*': {
		pageType: 'accessories'
	},
	'.*CERTIFIED_SERVICE.*': {
		pageType: 'certifiedservice'
	},
	'.*MAINTENANCE_MENU.*': {
		pageType: 'servicemenu',
		pageSubType: 'Maintenance Menu'
	},
	'.*PARTS_LEAD.*': {
		pageType: 'parts',
		pageSubType: 'Parts Lead'
	},
	'.*SCHEDULE_(AN_APPOINTMENT|SERVICE).*': {
		pageType: 'scheduleservice',
		pageSubType: 'Schedule Service'
	},
	'.*SERVICE_LEAD_OSS.*': {
		pageType: 'scheduleservice'
	},
	'^INVENTORY_COMPARE_.*': {
		pageType: 'showroom'
	},
	'.*(SHOWROOM|MODEL_SPECIFIC_INDEX).*': {
		pageType: 'showroom',
		pageSubType: 'New Vehicles Showroom'
	},
	'.*SITEMAP.*': {
		pageType: 'helpcenter'
	},
	'^AUTO.*(BARGAIN|USED).*DETAILS.*': {
		pageTool: 'search inventory',
		pageType: 'vehicledetails',
		pageSubType: 'Used Vehicle Details'
	},
	'^AUTO.*CERTIFIED.*DETAILS.*': {
		pageTool: 'search inventory',
		pageType: 'vehicledetails',
		pageSubType: 'CPO Vehicle Details'
	},
	'^AUTO.*NEW.*DETAILS.*': {
		pageTool: 'search inventory',
		pageType: 'vehicledetails',
		pageSubType: 'New Vehicle Details'
	},
	'.*INVENTORY_LISTING.*(ALL|NO_RESULTS).*': {
		pageTool: 'search inventory',
		pageType: 'vehiclesearchresults',
		pageSubType: 'All Inventory Search Results'
	},
	'.*INVENTORY_LISTING.*(BARGAIN|USED).*': {
		pageTool: 'search inventory',
		pageType: 'vehiclesearchresults',
		pageSubType: 'Used Inventory Search Results'
	},
	'.*INVENTORY_LISTING.*CERTIFIED.*': {
		pageTool: 'search inventory',
		pageType: 'vehiclesearchresults',
		pageSubType: 'CPO Inventory Search Results'
	},
	'.*INVENTORY_LISTING.*NEW.*': {
		pageTool: 'search inventory',
		pageType: 'vehiclesearchresults',
		pageSubType: 'New Inventory Search Results'
	},
	'.*(ADVANCED_LEASE|APPEARANCE_GUARD).*': {
		pageType: 'protection'
	},
	'.*(WARRANTY|GAP_COVERAGE|XS_WEAR).*': {
		pageType: 'protection'
	},
	'.*(PREPAID_MAINTENANCE|PROTECTION).*': {
		// There is no pattern in these to determine protection so splitting them
		pageType: 'protection'
	},
	'.*BATTERY.*': {
		pageType: 'battery',
		pageSubType: 'Battery Service'
	},
	'.*BRAKE.*': {
		pageType: 'brakes',
		pageSubType: 'Brake Service'
	},
	'.*CPO_VEHICLE.*': {
		pageType: 'cpo',
		pageSubType: 'CPO Vehicle'
	},
	'.*FLEET.*': {
		pageType: 'fleet'
	},
	'.*(NEW_TIRES|TIRE_QUOTE|TIRE_REBATE).*': {
		pageType: 'tires'
	},
	'.*OIL_CHANGE.*': {
		pageType: 'oilchange',
		pageSubType: 'Oil Change'
	},
	'.*PARTS_ORDER.*': {
		pageType: 'partsorder',
		pageSubType: 'Parts Order'
	},
	'.*REFERRAL_PROGRAM.*': {
		pageType: 'discountprograms',
		pageSubType: 'Referral Program'
	},
	'.*REWARDS.*': {
		pageType: 'rewardsprogram',
		pageSubType: 'Rewards'
	},
	'.*SERVICE_AND_PART.*SPECIALS.*': {
		pageType: 'serviceoffers',
		pageSubType: 'Service and Parts Specials'
	},
	'.*TIRE.*FINDER.*': {
		pageType: 'tirefinder',
		pageSubType: 'Tire Finder'
	},
	'.*TIRE.*': {
		pageType: 'service',
		pageSubType: 'Tires'
	},
	'.*SERVICE.*': {
		// Keeping it at the bottom if any of the above service types doesn't match, this will be used
		pageType: 'service'
	}
};
